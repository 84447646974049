<template>
  <div id="setupContainer">
    <el-row>
      <el-col :span="24">
        <el-card shadow="never" style="border-color: transparent;">
          <!-- Scan QR code instruction -->
          <el-row type="flex" justify="center">
            <!-- eslint-disable-next-line -->
            {{ __("Scan this QR code using Authenticator App") }}
          </el-row>

          <!-- Generated QR code -->
          <el-row type="flex" justify="center">
            <span v-html="getQRCode"></span>
          </el-row>

          <el-divider></el-divider>

          <!-- Verify instruction -->
          <el-row type="flex" justify="center">
            {{ validationInstruction }}
          </el-row>

          <!-- Validate textbox -->
          <InputTFA v-model="verifyCode"></InputTFA>
        </el-card>
      </el-col>
    </el-row>

    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Translation from "@/mixins/translation";
import InputTFA from "./InputTFA";

export default {
  components: { InputTFA },
  name: "SetupTFA",
  mixins: [Translation],
  props: {
    value: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    ...mapState("users", {
      two_fa: "two_fa"
    }),
    getQRCode() {
      return this.two_fa.qr_code;
    },
    validationInstruction() {
      // eslint-disable-next-line
      return __("Enter the :digit-digit authentication code generated by your app", {digit: this.digits});
    },
    verifyCode: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
  data() {
    return {
      digits: 6
    };
  }
};
</script>

<style lang="css" scoped>
#setupContainer {
  width: 100%;
}
</style>
