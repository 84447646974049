<template>
  <div id="digitContainer">
    <div class="digitInput" v-for="(digit, idx) in twoFaCode" :key="idx">
      <input
        ref="digitInput"
        class="el-input__inner"
        style="text-align: center;"
        v-model="digitInput[idx]"
        v-on:input="event => digitInputAction(event, idx)"
        v-on:keyup="event => onKeyupAction(event, idx)"
        type="text"
      />
    </div>
  </div>
</template>

<script>
const EMPTY_CHAR_PLACEHOLDER = "\n";
export default {
  EMPTY_CHAR_PLACEHOLDER,
  name: "InputTFA",
  props: {
    /**
     * v-model value
     */
    value: {
      type: String
    },

    /**
     * Number of charCount in the code
     */
    charCount: {
      type: Number,
      required: false,
      default: () => 6
    },

    /**
     * Regex pattern that defines the acceptable charset
     */
    charset: {
      type: RegExp,
      default: () => /\d/
    }
  },
  data() {
    return {
      /**
       * Temporary container for digit input
       */
      digitInput: []
    };
  },
  computed: {
    twoFaCode: {
      get() {
        // Need to move the getter to its own function. There's a race condition when
        // reading user input using props
        return this.getModelValue();
      },
      set(newValue) {
        this.$emit("input", newValue.splice(0, this.charCount).join(""));
      }
    },
    charsetPattern() {
      return this.charset;
    }
  },
  methods: {
    /**
     * Update the digit of the code
     * @param {InputEvent} eventObj
     * @param {Number} idx Position to update
     */
    updateDigit(event, idx) {
      const userInput = this.digitInput[idx].split("");
      let newTwoFaCode = [...this.getModelValue()];

      // New digit input could be more than 1 character (i.e. when pasting)
      // Copy each character to the input buffer and output buffer from the back
      // since we only want to copy characters that are in the charset
      let bufferOffset = userInput.length;
      for (let i = userInput.length - 1; i >= 0; --i) {
        --bufferOffset;

        // Index is outside the buffer
        if (idx + bufferOffset > this.characterCount) {
          continue;
        }

        // New index position is less than the index of the selected digit index
        if (idx + bufferOffset < idx) {
          continue;
        }

        // New character is not in the charset. Read value from model
        if (`${userInput[i]}`.match(this.charsetPattern) === null) {
          continue;
        }

        // If all check passed, then we can update the character in the model
        // with the new character
        newTwoFaCode[idx + bufferOffset] = userInput[i];
      }

      // If new input is empty, then simply set the current character to no char
      if (userInput.length === 0) {
        newTwoFaCode[idx] = EMPTY_CHAR_PLACEHOLDER;
      }

      // Update model & input buffer with cleaned values
      this.digitInput = [...newTwoFaCode];
      this.twoFaCode = [...newTwoFaCode];
    },

    /**
     * Get the model value. This will directly read from the model, bypassing vue computed props
     */
    getModelValue() {
      let digit = this.value.split("");

      while (digit.length < this.charCount) {
        digit.push("");
      }

      return digit.splice(0, this.charCount);
    },

    /**
     * Set focus to digit input given index
     * @param {*} idx Index of input to gain focus
     * @returns {boolean} True if an empty digit is found and focus is given. Otherwise false
     */
    setFocusToIndex(idx) {
      if (this.$refs["digitInput"][idx] === undefined) {
        return false;
      }

      this.$refs["digitInput"][idx].focus();
      this.$refs["digitInput"][idx].select();

      return true;
    },

    /**
     * Remove focus from all digit input
     */
    removeFocus() {
      if (this.$refs["digitInput"][0] === undefined) {
        return false;
      }

      this.$refs["digitInput"][0].focus();
      this.$refs["digitInput"][0].blur();

      return true;
    },

    /**
     * Set input focus to the first empty digit
     * @returns {boolean} True if an empty digit is found and focus is given. Otherwise false
     */
    changeFocusToFirstEmptyDigit() {
      for (let i = 0; i < this.digitInput.length; ++i) {
        if (["", EMPTY_CHAR_PLACEHOLDER].indexOf(this.digitInput[i]) !== -1) {
          return this.setFocusToIndex(i);
        }
      }

      return false;
    },

    /**
     * Set of actions to perform upon input event
     * @param {InputEvent} event
     * @param {Number} idx
     */
    digitInputAction(event, idx) {
      this.updateDigit(event, idx);
      this.setFocusToIndex(idx + 1);
    },

    /**
     * Actions to perform on key up
     * @param {KeyboardEvent} event The keyboard event
     * @param {Number} idx Index of the digit the element represents
     */
    onKeyupAction(event, idx) {
      this.changeIdxByKeystroke(event, idx);
    },

    /**
     * Change the active index on keyboard event
     * @param {KeyboardEvent} event The keyboard event
     * @param {Number} idx Starting index
     */
    changeIdxByKeystroke(event, idx) {
      const leftIdxKeys = ["Backspace", "ArrowLeft"];
      const rightIdxKeys = ["ArrowRight"];

      if (leftIdxKeys.indexOf(event.key) !== -1) {
        this.setFocusToIndex(idx - 1);
      } else if (rightIdxKeys.indexOf(event.key) !== -1) {
        this.setFocusToIndex(idx + 1);
      }
    },

    /**
     * Load 2FA code to temp array
     */
    loadModelValue() {
      this.digitInput = this.twoFaCode;
    }
  },
  watch: {
    value() {
      this.loadModelValue();
    },
    digitInput() {
      if (!this.changeFocusToFirstEmptyDigit()) {
        this.setFocusToIndex(this.charCount - 1);
      }
    }
  },
  mounted() {
    this.loadModelValue();
  }
};
</script>

<style scoped>
#digitContainer {
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

.digitInput {
  margin: 0 5px;
  width: 60px;
  text-align: center;
}

.digitInput > input {
  width: 100%;
  border-radius: 5px;
  font-size: 18pt;
  padding: 5px 0;
}

.digitInput > input:focus {
  outline: none;
  border-color: var(--theme-outline-color);
}
</style>
