<template>
  <div class="setupOtpForm" v-loading.fullscreen.lock="isLoading">
    <div class="loginContainer">
      <el-button
        icon="el-icon-arrow-left"
        plain
        text
        class="backBtn"
        @click="redirectToLogin"
      >
        {{ __("Back to Login") }}
      </el-button>
      <div class="setup-otp-title">
        <div>
          {{ __("Setup 2FA") }}
        </div>
      </div>
      <div v-if="isAuthError">
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <span class="scan-qr-info">
              {{ __("Error fetching QR code. Please try again later") }}
            </span>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <el-row type="flex" justify="left">
          <SetupTFA v-model="credentials.verify_twofa">
            <el-row type="flex">
              <el-button
                id="proceed-btn"
                class="w-full text"
                type="primary"
                :loading="isLoading"
                @click="setup2FA"
              >
                {{ __("PROCEED") }}
              </el-button>
            </el-row>
          </SetupTFA>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Translation from "@/mixins/translation";
import session from "@/utils/sessionManager";
import store from "@/store";
import SetupTFA from "@/components/2FA/SetupTFA";

export default {
  components: {
    SetupTFA
  },
  name: "setupOtp",
  mixins: [Translation],
  data() {
    return {
      redirectUrl: "",
      credentials: {
        email: "",
        verify_twofa: ""
      },
      isLoading: true,
      isAuthError: false,
      token: "",
      qrCode: ""
    };
  },
  async mounted() {
    this.credentials.email = this.$route.params.email;
    this.redirectUrl = this.$route.params.redirectUrl;
    this.token = this.$route.params.token;
    this.qrCode = this.$route.params.qr_code;
    this.fetch2FA();
  },
  methods: {
    ...mapActions("auth", {
      verifyOtp: "verifyOtp"
    }),
    ...mapActions("users", {
      get2FA: "get2FA",
      set2faQrCode: "set2faQrCode"
    }),
    clearLoginSession() {
      session.clearAll();
      window.localStorage.clear();
      store.dispatch("app/resetAppState");
    },
    fetch2FA() {
      this.isLoading = false;
      this.set2faQrCode({
        qr_code: this.qrCode
      });
    },
    showErrorMessage() {
      this.isAuthError = true;
      this.isLoading = false;
      this.$message({
        message: __("Operation Failed"),
        type: "error"
      });
    },
    setup2FA() {
      let self = this;
      this.verifyOtp({
        one_time_password: this.credentials.verify_twofa,
        otp_link_token: this.token
      })
        .then(data => {
          self.$message({
            message: data.data.message,
            type: "success"
          });
          this.redirectToLogin();
        })
        .catch(res => {
          console.log(res);
          let message =
            res.message === null || res.message === undefined
              ? __("Operation Failed")
              : res.message;
          self.$message({
            message,
            type: "error"
          });
        });
    },
    redirectToLogin() {
      this.$router.push({ path: "/login" }).catch(() => {});
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
.setupOtpForm {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginContainer {
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  width: 400px;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  .setup-otp-title {
    font-weight: bolder;
    font-size: 36px;
    display: flex;
    flex-direction: row;
    color: $--color-text-primary;
    margin-bottom: 50px;
  }

  ::v-deep .el-form-item--medium .el-form-item__label {
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  ::v-deep .el-form-item__label {
    display: flex;
    justify-content: right;
  }

  #proceed-btn {
    border-radius: 5px;
    height: 50px;
  }

  .que {
    cursor: pointer;
  }
}

.scan-qr-info {
  display: inline-block;
  text-align: center;
  font-size: 17px;
}

.backBtn {
  border: none;
  font-size: 14px;
  font-weight: bold;
  padding-left: 0px;

  ::v-deep i {
    font-size: 18px;
    font-weight: bolder;
  }

  ::v-deep span {
    margin-bottom: 6px;
  }
}

.w-full {
  width: 100%;
}
</style>
